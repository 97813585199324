<template>
  <Layout ref="layout">
    <div v-if="isWorkgroupSuspend != true" class="head-enterprise-page px-4 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <button v-if="!canAddNewMember" :disabled="!isOwner" class="btn-add-member-enterprise" v-b-modal.modalPackages>Invite</button>
        <button v-else :disabled="!isOwner" class="btn-add-member-enterprise" @click="addParticipants()">Invite</button>
        <div class="input-search-member">
            <img src="@/assets/images/icon/search-black.svg" alt="" style="height: 16px; width: 16px;">
            <input v-model="search" type="text" placeholder="Search participant">
        </div>
      </div>
    </div>
    <div v-if="isWorkgroupSuspend != true" class="list-enterprise-member px-4">
      <table class="table-enterprise">
        <thead>
          <th>Name</th>
          <th>Role</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(participant, index) in filteredParticipant" :key="index">
            <td>
              <div class="d-flex align-items-center">
                <div v-if="participant.status == 3" class="avatar-xs">
                  <span class="avatar-title-api rounded-circle">{{participant.firts_name.charAt(0).toUpperCase()}}</span>
                  <span v-if="participant.access_type == 'Owner'" :class="isOwnerOnline ? 'status-online' : 'status-offline'"></span>
                  <span v-else :class="participant.online == true ? 'status-online' : 'status-offline'"></span>
                </div>
                <img v-else src="@/assets/images/icon/pending-user.svg" style="height: 32px; width: 32px;" alt="">
                <span v-if="participant.status == 3" class="ml-2 enterprise-member-list-text">
                  {{participant.firts_name}} {{participant.lastname}}
                </span>
                <span v-else class="ml-2 enterprise-member-list-text">
                  {{participant.email}}
                </span>
              </div>
            </td>
            <td v-if="participant.access_type == 'Owner'">
              <span class="enterprise-member-list-text">
                Owner
              </span>
            </td>
            <td v-if="participant.access_type != 'Owner' && participant.status == 3">
              <b-dropdown v-if="isOwner && workgroup.type != 'PRIVATE'" id="dropdown-change-access">
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <span class="enterprise-member-list-text mr-2">{{ participant.access_type == "View" ? 'Viewer' : 'Editor' }}</span>
                    <img src="@/assets/images/icon/chevDown.svg" alt="">
                  </div>
                </template>
                <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="updateAccess(participant)">
                  <div class="d-flex justify-content-start ml-2">
                    <img v-if="access.id == participant.access_type" src="@/assets/images/icon/Checklist.svg" alt="">
                    <span v-else class="mx-2"></span>
                    <span class="enterprise-member-list-text mx-3">{{ access.label }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <div v-else class="enterprise-member-list-text">
                <span>{{ workgroup.type != 'PRIVATE' ? (participant.access_type == "View" ? 'Viewer' : 'Editor') : 'Restricted' }}</span>
              </div>
            </td>
            <td v-if="participant.access_type != 'Owner' && participant.status != 3">
              <b-dropdown v-if="isOwner" id="dropdown-resend">
                <template #button-content>
                  <span class="enterprise-member-list-text mr-2">{{ participant.status == 5 ? 'Failed' : 'Pending'}}</span>
                  <img src="@/assets/images/icon/chevDown.svg" alt="" class="">
                </template>
                <b-dropdown-item @click="resendInv(participant.email)">
                  <div class="d-flex justify-content-center align-items-center">
                    <img src="@/assets/images/icon/resend-add.svg" alt="" class="">
                    <span class="enterprise-member-list-text ml-3">Resend</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <div v-else class="enterprise-member-list-text">
                <span>{{ participant.status == 5 ? 'Failed' : 'Pending'}}</span>
              </div>
            </td>
            <td v-if="participant.access_type == 'Owner'">
              <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-wg-setting">
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <img src="@/assets/images/icon/btn-more.svg" alt="">
                  </div>
                </template>
                <b-dropdown-item @click="copyToClipboard(participant.email)">
                  <div class="d-flex justify-content-start ml-2">
                    <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                    <span class="enterprise-member-list-text mx-3">Copy email</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </td>
            <td v-if="participant.access_type != 'Owner'">
              <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-wg-setting">
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <img src="@/assets/images/icon/btn-more.svg" alt="">
                  </div>
                </template>
                <b-dropdown-item @click="copyToClipboard(participant.email)">
                  <div class="d-flex justify-content-start ml-2">
                    <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                    <span class="enterprise-member-list-text mx-3">Copy email</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item v-if="isOwner" @click="removeParticipant(participant.email)">
                  <div class="d-flex justify-content-start ml-2">
                    <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt="">
                    <span class="enterprise-member-list-text mx-3">Remove</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="filteredParticipant.length <= 0 && search !== ''" class="d-flex justify-content-center align-items-center flex-column">
        <img src="@/assets/images/empty-member-enterprise.svg" class="mb-1" style="height: 128px; width: 128 px;" alt="">
        <span class="mb-1 empty-word-title">Participant not found!</span>
      </div>
      <div v-else-if="filteredParticipant.length <= 0" class="d-flex justify-content-center align-items-center flex-column">
        <img src="@/assets/images/empty-member-enterprise.svg" class="mb-1" style="height: 128px; width: 128 px;" alt="">
        <span class="mb-1 empty-word-title">No workgroup participant</span>
        <span class="empty-word-sub-title">The participant details you add to the workgroup will be shown here.</span>
      </div>
    </div>
    <SuspendWorkgroupPage v-if="isWorkgroupSuspend == true"/>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";

import SuspendWorkgroupPage from "./suspendWorkgroup.vue"

export default {
    data() {
      return {
        search: '',
        isDropdownResendVisible: false,
        isDropdownChangeAccess: false,
        accessTypes : [
            {
                id : "View",
                label : "Viewer",
                selected : true
            },
            {
                id : "Write",
                label : "Editor",
                selected : false
            }
        ]
      }
    },
    components: {
        Layout,
        SuspendWorkgroupPage
    },
    mounted() {
      if(this.$route.query.wgId){
        this.$store.commit('state/SET_STATE', 'workgroup')
        this.isEnterprise()
        this.$store.dispatch("workgroup/retrieve", this.$route.query.wgId)
        .then(()=>{
          let links = document.getElementsByClassName("nav-bar-link-ref");
          let matchingMenuItem = null;
          for (let j = 0; j < links.length; j++) {
              if (window.location.pathname === links[j].pathname) {

                  matchingMenuItem = links[j];
                  break;
              }
          }
          matchingMenuItem.classList.add('active')
        })
      }
        // if(this.state == 'storage'){
        //     this.$router.push({name: 'home'})
        // } else {
        //   this.getWorkgroup()
        // }
      this.$ga.event(process.env.VUE_APP_GA_WG_SET_LOAD_PAGE_PARTICIPANTS, 'WG - Access Page Participant', 'wg-settings')
    },
    computed: {
        isWorkgroupSuspend() {
          return this.$store.getters['workgroup/statusSuspendWorkgroup']
        },
        canAddNewMember(){
          var subdomain = window.location.host.split('.')
          var nameSubdomain = subdomain[0]
          // var nameSubdomain = 'fafafufu'
          // var nameSubdomain = 'jayent-mydata'
          // var nameSubdomain = 'eventmyeve'
          // var nameSubdomain = 'yangunik'
          if(nameSubdomain == process.env.VUE_APP_X_DOMAIN){
            // var userPackage = this.$store.getters['user/package'];
            // if(this.workgroup){
            //   var participants = this.workgroup.participant;
            //   return participants.length < userPackage.workgroup.max_participants + 1
            // }
            return this.getTotalParticipant()
          } else {
            return true
          }
        },
        filteredParticipant() {
            return this.$store.getters['workgroup/filteredParticipant'].filter((participant) => {
                if(participant.access_type == 'Owner'){
                    if(participant.firts_name.toLocaleLowerCase().match(this.search.toLocaleLowerCase())) {
                        return participant
                    }
                } else {
                    if(participant.status == 3){
                        if(participant.firts_name.toLocaleLowerCase().match(this.search.toLocaleLowerCase()) || participant.lastname.toLocaleLowerCase().match(this.search.toLocaleLowerCase())){
                            return participant
                        }
                    } else {
                        if(participant.email.toLocaleLowerCase().match(this.search.toLocaleLowerCase())){
                            return participant
                        }
                    }
                }
            })
        },
        isOwnerOnline() {
            var wg = this.$store.getters['workgroup/workgroup']
            var participant = this.$store.getters['workgroup/workgroup'].participant
            var owner = participant.filter((p) => {
                return p._id == wg.owner_id
            })
            var isOnline = owner[0].online
            return isOnline
        },
        isOwner(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            if(group == null) {
                return true
            } else {
                return id == group.owner_id;
            }
        },
        state(){
            return this.$store.getters['state/state'];
        },
        workgroup() {
            return this.$store.getters['workgroup/workgroup']
        }
    },
    methods: {
        isEnterprise(){
          var subdomain = window.location.host.split('.')
          var nameSubdomain = subdomain[0]
          // var nameSubdomain = 'fafafufu'
          // var nameSubdomain = 'jayent-mydata'
          // var nameSubdomain = 'eventmyeve'
          // var nameSubdomain = 'yangunik'
          if(nameSubdomain == process.env.VUE_APP_X_DOMAIN){
            this.$store.dispatch('packages/getPackages')
          }
        },
        getTotalParticipant(){
          var userPackage = this.$store.getters['user/package'];
          if(this.workgroup){
            let participants = this.workgroup.participant;
            return participants.length < userPackage.workgroup.max_participants
          } else {
            this.$store.dispatch("workgroup/retrieve", this.$route.query.wgId)
            .then(()=>{
              let participants = this.workgroup.participant;
              return participants.length < userPackage.workgroup.max_participants
            })
          }
        },
        addParticipants(){
          this.$ga.event(process.env.VUE_APP_GA_CREATE_WORKGROUP_BTN_INVITE, 'Create WG - Add Participants', 'wg-action', 1)
          this.$bvModal.show('modal-add-participant-v3')
        },
        getWorkgroup(){
          if(this.workgroup){
            this.$store.dispatch("workgroup/retrieve", this.workgroup._id)
          } else {
            this.$store.dispatch("workgroup/retrieve", this.$route.query.wgId)
          }
        },
        copyToClipboard(email) {
            navigator.clipboard.writeText(email)
            this.$toasted.show('Email copied',{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        },
        resendInv(email) {
            var payload = {
                workgroup_id: this.workgroup._id,
                email: email
            }
            this.$store.dispatch('workgroup/resendInvite', payload)
            .then(() => {
                this.$store.dispatch("workgroup/retrieve",this.workgroup._id)
                this.$toasted.show('Invitation has been resent to this email.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            })
            .catch(() => {
                this.$toasted.show('Invitation failed to resent. Try again later.',{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
                })
            })
        },
        updateAccess(participant){
            var data = {
                id : this.workgroup._id,
                email : participant.email,
                access_type : participant.access_type == "View" ? "Write" : "View"
            }
            this.$store.dispatch("workgroup/changeAccess",data)
        },
        removeParticipant(email){
          this.$ga.event(process.env.VUE_APP_GA_WG_SET_BTN_DEL_PARTICIPANT, 'WG - Remove Particpants', 'wg-settings')
            var params = {
                workgroup_id : this.workgroup._id,
                email : email
            }
            this.$store.dispatch("workgroup/removeParticipant",params)
            .then(()=>{
                this.$store.dispatch("workgroup/retrieve",this.workgroup._id)
                this.$toasted.show('This participant has been removed by you.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            })
            .catch(() => {
                this.$toasted.show('Something is wrong! Try again later.',{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
                })
            })
        }
    }
}
</script>

<style>

</style>